import React from 'react'

export const Inicio = () => {
  return (
    <section className='inicio'>

        <div className='inicio__bienvenida'>
            <div className='inicio__bienvenida-img'>
                <img src='https://live.staticflickr.com/65535/53583425172_7029a0bb33.jpg' alt='flores' />
            </div>

           <div className='inicio__bienvenida-text'>
                <p className='inicio__bienvenida-p1'>¡Bienvenido a 3 LUNAS! <br/> Donde tus ideas toman vida</p>
                <p className='inicio__bienvenida-p2'>Permítenos ser tu socio creativo en la celebración de tus momentos más significativos. Explora nuestro catálogo y descubre cómo podemos hacer realidad tus sueños de regalo.</p>
                <p className='inicio__bienvenida-p2'>¡Únete a nosotros y haz que cada ocasión sea memorable con nuestros exclusivos regalos diseñados especialmente para ti!</p>
           </div>
        </div>

        <div className='inicio__why'>
            <p className='inicio__why-titulo'>¿POR QUÉ 3 LUNAS?</p>

            <p className='inicio__why-text'>Aquí nos enorgullece ofrecerte un servicio único para cualquier evento, donde tu creatividad es la inspiración para cada detalle. Nos dedicamos a transformar tus ideas en exquisitos arreglos, diseñados con esmero y atención a tus deseos más específicos</p>


        </div>

        <div className='inicio__apartados'>

            <div className='inicio__apartados__circles'>
                <div className='inicio__apartados__circles-circle'>
                    <img src='https://live.staticflickr.com/65535/53588579470_feb5b8b75f_w.jpg' alt='' />
                </div>
                <p>Cumpleaños</p>
            </div>
            <div className='inicio__apartados__circles'>
                <div className='inicio__apartados__circles-circle'>
                    <img src='https://live.staticflickr.com/65535/53588453818_22bfd5434d_n.jpg' alt='' />
                </div>
                <p>Amor</p>
            </div>
            <div className='inicio__apartados__circles'>
                <div className='inicio__apartados__circles-circle'>
                    <img src='https://live.staticflickr.com/65535/53589520356_13caace244_w.jpg' alt='' />
                </div>
                <p>Graduación</p>
            </div>
            <div className='inicio__apartados__circles'>
                <div className='inicio__apartados__circles-circle'>
                    <img src='https://live.staticflickr.com/65535/53588242631_c8e5ed0202_n.jpg' alt='' />
                </div>
                <p>Felicitaciones</p>
            </div>
            <div className='inicio__apartados-info'>

                <p>En nuestro espacio digital, cada detalle es creado según tu idea. Nos enorgullece ofrecerte un servicio personalizado donde tu creatividad es la inspiración principal. Desde la selección de colores y materiales hasta la disposición de cada elemento, nos dedicamos a plasmar tus sueños en cada regalo.</p>

            </div>

        </div>

        <section className='inicio__top'>
            <p className='inicio__top__tittle'>LO MAS VENDIDO</p>
           <div className='inicio__top__seccion'>
                <div className='inicio__top__seccion-img'>
                    <img src="https://live.staticflickr.com/65535/53589928385_918132256e_w.jpg" alt="corazon de chocolates" />
                    
                </div>
                <div  className='inicio__top__seccion-color'>
                    <p>Caja ramo de chocolates</p>
                </div>
           </div>
           <div className='inicio__top__seccion'>
                 <div className='inicio__top__seccion-color'>
                    <p>Caja peluche sorpresa</p>
                </div>
                <div className='inicio__top__seccion-img'>
                    <img src="https://live.staticflickr.com/65535/53589717413_b5419155c4_w.jpg" alt="" />                   
                </div>
                
           </div>
           <div className='inicio__top__seccion'>
                <div className='inicio__top__seccion-img'>
                    <img src="https://live.staticflickr.com/65535/53588132246_7bfdc295c7_n.jpg" alt="" />                    
                </div>
                <div className='inicio__top__seccion-color'>
                    <p>Globo con caja de dulces favoritos</p>
                </div>
           </div>
           <div className='inicio__top__seccion'>
                <div className='inicio__top__seccion-color'>
                    <p>Caja ramo de chocolates</p>
                </div>
                <div className='inicio__top__seccion-img'>
                    <img src="https://live.staticflickr.com/65535/53589928400_9a5ece9317_w.jpg" alt="" />                    
                </div>
           </div>


        </section>
    </section>
  )
}
