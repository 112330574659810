import React from 'react'
import {ramosTematicos} from '../DATOS/catalogoRamosTematicos'
import { Link } from 'react-router-dom'

export const CatalogoRamosTematicos = () => {
  return (
    <div className='catalogoP'>

        <Link to="/catalogos"><i class="fa-solid fa-circle-chevron-left"></i></Link>

        <p className='catalogoP__tittle'>RAMOS TEMATICOS</p>
        <p className='catalogoP__texto'>¡Haz de cada ocasión una experiencia única con nuestros diseños personalizados!</p>


        <section className='listaCatalogos'>

            {
                ramosTematicos.map((articulo,id) =>{
                    return(

                        <div className='listaCatalogos__catalogo' key={id}>
                            <div className='listaCatalogos__catalogo-img'>
                                <img src={articulo.img}/>
                            </div>
                            
                            <p>{articulo.nombre}</p>
            
                        </div>

                    )
                })
            }        
        </section>

    </div>
  )
}
