export const globosPersonalizados = [
    {
        "img":"https://live.staticflickr.com/65535/53588343003_ed510cdde8_w.jpg",
        "nombre":"Globo feliz cumpleaños con caja sorpresa",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588452989_2969b863bd_w.jpg",
        "nombre":"Globo texto personalizado",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588342978_c2faf9a52e_w.jpg",
        "nombre":"Globo propuesta de matrimonio",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588452939_4a2dc96596_w.jpg",
        "nombre":"Globo infantil",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588132246_7bfdc295c7_w.jpg",
        "nombre":"Globo con caja dulces favoritos",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588452944_6708220e2c_w.jpg",
        "nombre":`Globo "Quieres ser mi novia"`,
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588579470_feb5b8b75f_n.jpg",
        "nombre":"Globo feliz cumpleaños con canasta de girasoles",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588342953_0b7b673f8b.jpg",
        "nombre":"Globos personalizados con canasta de dulces favoritos",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588452929_e98139012e_n.jpg",
        "nombre":"Feliz San Valentín con luces led",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588342948_dd4e5e674d_n.jpg",
        "nombre":"Globos temáticas de caricatura",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
]