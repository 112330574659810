export const flores = [
    {
        "img":"https://live.staticflickr.com/65535/53588453818_22bfd5434d_n.jpg",
        "nombre":"Ramo de girasoles con rosas",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53587374527_c61cef0c2a_n.jpg",
        "nombre":"Ramo mini rosas con detalles de decoración",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588691275_18efa7d95c_n.jpg",
        "nombre":"Ramos flores azules y detalles de decoración",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588563974_f1c9024cdf_n.jpg",
        "nombre":"Ramo personalizado con letrero",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588242651_8cb9f7a217_n.jpg",
        "nombre":"Ramos eterno de girasoles y rosas",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588453763_d0be4be4fa_n.jpg",
        "nombre":"Ramo carritos de hot-wheels",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588563959_452887bd32_n.jpg",
        "nombre":"Ramos tulipanes eternos",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588242631_c8e5ed0202_n.jpg",
        "nombre":"Ramo gerberas",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588563949_d801cf7ed5_n.jpg",
        "nombre":"Oso ramo",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    
    
    
    
]