import './App.scss';
import { RutaPrincipal } from './RUTAS/RutaPrincipal';

function App() {
  return (
    <>
      <RutaPrincipal/>
    </>
  );
}

export default App;
