export const catalogos = [
    {
        "id":"flores",
        "nombre":"Flores",
        "img":"https://live.staticflickr.com/65535/53588563974_f1c9024cdf_n.jpg",
        
    },
    {
        "id":"globos-personalizados",
        "nombre":"Globos Personalizados",
        "img":"https://live.staticflickr.com/65535/53588132246_7bfdc295c7_n.jpg",
        
    },
    {
        "id":"Caja-de-dulces",
        "nombre":"Caja de Dulces",
        "img":"https://live.staticflickr.com/65535/53589988889_21143db3d9_z.jpg",
        
    },
    {
        "id":"ramos-tematicos",
        "nombre":"Ramos Temáticos",
        "img":"https://live.staticflickr.com/65535/53589708163_40e48801b9_w.jpg",
        
    },
    {
        "id":"peluche-magico",
        "nombre":"Peluche Mágico",
        "img":"https://live.staticflickr.com/65535/53589717418_ce0ae6719b_w.jpg",
        
    },
    {
        "id":"graduaciones",
        "nombre":"Graduaciones",
        "img":"https://live.staticflickr.com/65535/53589520356_13caace244_w.jpg",
        
    }

]