export const graduaciones = [
    {
        "img":"https://live.staticflickr.com/65535/53588647927_10f4aeaceb_w.jpg",
        "nombre":"Ramo graduación",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589520356_13caace244_w.jpg",
        "nombre":"Birrete con peluche",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588647907_94ebd17aed_w.jpg",
        "nombre":"Caja sorpresa graduación",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589725553_8055c80803_w.jpg",
        "nombre":"Globo personalizado con rosas",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589520321_b11db65138_w.jpg",
        "nombre":"Globo graduación",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589725548_bb99a44af6_w.jpg",
        "nombre":"Birrete ramo",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589965185_9cf15cfe17_w.jpg",
        "nombre":"Hot-Wheels graduación",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589965190_9a37f9c0fd_w.jpg",
        "nombre":"Arreglo birrete graduación",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588647902_fd1678cfa1_w.jpg",
        "nombre":"Oso graduado",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
]