export const ramosTematicos = [
    {
        "img":"https://live.staticflickr.com/65535/53588242641_b4d693a895_n.jpg",
        "nombre":"Dulce ramo",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588630102_ef13716be1_w.jpg",
        "nombre":"Ramo spiderman",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589708163_40e48801b9_w.jpg",
        "nombre":"Ramo de pato",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589502371_042632b70f_n.jpg",
        "nombre":"Ramo Winnie Pooh",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588630092_fbee3ecc9b_n.jpg",
        "nombre":"Ramo futbolista",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589947805_3aa1aa4f08_n.jpg",
        "nombre":"Ramo princesa",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589708668_231bea7198_n.jpg",
        "nombre":"Ramo de cerdito",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589708663_43a78bc9ac_n.jpg",
        "nombre":"Ramo grinch",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    }
]