export const cajaDeDulces = [
    {
        "img":"https://live.staticflickr.com/65535/53589688613_3486ddb863_w.jpg",
        "nombre":"Caja gomi flores",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589482741_e689df152a_w.jpg",
        "nombre":`Caja sorpresa "Quieres ser mi novia"`,
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589688583_0f95a32378_w.jpg",
        "nombre":"Caja dulce feliz cumpleaños",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589928410_abc714afa6_w.jpg",
        "nombre":"Caja circular chocolates-flores",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53588610607_bf466f7797_w.jpg",
        "nombre":"Caja carrito chocolates y flores",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589928400_9a5ece9317_n.jpg",
        "nombre":"Caja ramo chocolates",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589928385_918132256e_n.jpg",
        "nombre":"Caja ramo chocolates",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },{
        "img":"https://live.staticflickr.com/65535/53589688533_dac2cc83b0_n.jpg",
        "nombre":"Caja carritos y rosas",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    }
]