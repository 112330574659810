import React from 'react'
import { Link } from 'react-router-dom'

export const PiePagina = () => {
  return (
    <div className='footer'>
        <div>
            <p className='footer__tittle'>Descubre promociones exclusivas en:</p>
            <Link to='https://www.instagram.com/3_lunas_arreglosyflores' target='blank'><i class="fa-brands fa-instagram"></i></Link>
        </div>
        <div>
            <p>Siguenos en: </p>
            <Link to='https://www.instagram.com/3_lunas_arreglosyflores' target='blank'><i class="fa-brands fa-instagram"></i></Link>
            <Link to='https://www.facebook.com/profile.php?id=61557413906857&mibextid=ZbWKwL' target='blank'><i class="fa-brands fa-facebook"></i></Link>
        </div>

        <p>Copyright <i class="fa-regular fa-copyright"></i> 3 Lunas 2024 </p>
        <p>Creado por CodeHouse </p>
        <img src='favicon1.ico' alt='logo'/>
    
    </div>
  )
}
