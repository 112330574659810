import React from 'react'
import {catalogos} from '../DATOS/catalogos'
import { Link } from 'react-router-dom'

export const Catalogos = () => {
  return (
    <div className='catalogos'>
        <section className='catalogos__bienvenida'>
            <p>¡Bienvenido al apartado de catálogos! <br/>Aquí encontrarás regalos personalizados según tus gustos. También ofrecemos diseños listos para llevar, ¡puedes elegirlos tal cual están o modificarlos a tu gusto! <br/> ¡Descubre la variedad y encuentra el regalo perfecto!</p>
        </section>

        <p  className='catalogos__tittle'>CATALOGOS</p>

        {
            catalogos.map((catalogo,id) => {
                return(

                   
                    <section className='catalogos__catalogo' key={id}>

                        <div className='catalogos__catalogo-img'>
                            <img src={catalogo.img} alt='logo'/>
                        </div>

                        <Link to={'/catalogos/' + catalogo.id} ><p>{catalogo.nombre}</p></Link>

                    </section>
                  

                )
                
            })
        }        
    </div>
  )
}
