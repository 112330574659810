import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export const Whatsapp = () => {

    const[whats, setWhats] = useState(true)
    console.log(whats)
  return (
    <div className='whats'>

        {
            whats === true 
            ? 
            <i  class="fa-brands fa-whatsapp" onClick={() => setWhats (false)}></i>
            :
            <div className='whats__mensaje'> 
                <i class="fa-regular fa-circle-xmark" onClick={() => setWhats (true)}></i>

                <p className='whats__mensaje-texto'>¿Tienes un diseño en mente? ¡Contáctanos y comparte la foto para cotizar!</p>

                <Link 
                    to="https://api.whatsapp.com/send/?phone=7299184950&"
                    target='blank'
                >
                    <input 
                        type='submit'
                        value="Chatear"
                    />
                </Link>
            </div>

        }
    </div>
  )
}
