import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Menu } from '../COMPONENTES/Menu'
import { Inicio } from '../COMPONENTES/Inicio'
import { Contacto } from '../COMPONENTES/Contacto'
import { Catalogos } from '../COMPONENTES/Catalogos'
import { CatalogoFlores } from '../COMPONENTES/CatalogoFlores'
import { CatalogoGlobosPersonalizados } from '../COMPONENTES/CatalogoGlobosPersonalizados'
import { CatalogoPelucheMagico } from '../COMPONENTES/CatalogoPelucheMagico'
import { CatalogoCajaDulces } from '../COMPONENTES/CatalogoCajaDulces'
import { CatalogoRamosTematicos } from '../COMPONENTES/CatalogoRamosTematicos'
import { PiePagina } from '../COMPONENTES/PiePagina'
import { CatalogoGraduaciones } from '../COMPONENTES/CatalogoGraduaciones'
import { Whatsapp } from '../COMPONENTES/Whatsapp'



export const RutaPrincipal = () => {

  
  return (
    <div>
        <BrowserRouter>
            <Menu/>
            <div className='body'>
                <Routes>
                    <Route path='/' element={<Inicio/>}/>
                    <Route path='/inicio' element={<Inicio/>}/>
                    <Route path='/catalogos/' element={<Catalogos/>}/> 
                    <Route path={'/catalogos/flores' } element={<CatalogoFlores/>}/> 
                    <Route path='/catalogos/globos-personalizados' element={<CatalogoGlobosPersonalizados/>}/> 
                    <Route path='/catalogos/caja-de-dulces' element={<CatalogoCajaDulces/>}/> 
                    <Route path='/catalogos/ramos-tematicos' element={<CatalogoRamosTematicos/>}/> 
                    <Route path='/catalogos/peluche-magico' element={<CatalogoPelucheMagico/>}/> 
                    <Route path='/catalogos/graduaciones' element={<CatalogoGraduaciones/>}/> 

                        
                    <Route path='/contacto' element={<Contacto/>}/>
                </Routes>
            </div>
            <Whatsapp/>
            <PiePagina/>
        </BrowserRouter>
        
    </div>
  )
}
