import React, { useState } from 'react'

export const Contacto = () => {

    const [nombre, setNombre] = useState("");
    const [descripcion,setDescripcion] = useState("");
    const enviarFormulario = (e) =>{
        e.preventDefault();
        const url = `https://api.whatsapp.com/send/?phone=7299184950&text=Hola mi nombre es: ${nombre}%0AMe puedes cotizar este arreglo${descripcion}, Gracias`;
        window.open(url, '_blank')

    }


  return (
    <div className='contacto'>

        <div className='contacto__puntos'></div>
        <div className='contacto__color'></div>
        <div className='contacto__color2'></div>
        
        <form>
           
            <label 
                for="Nombre" 
            >Nombre</label>

            
            <input
                className='contacto__form-tex'
                type='text'
                name='nombre'
                placeholder='Nombre'
                onChange={(e) => setNombre(e.target.value)}

            />

            <label 
                for="descripcion" 
            >Mensaje</label>

            <textarea
                className='contacto__form-mensaje'
                name='descripcion'
                placeholder='Cuentanos sobre el regalo que quieres dar'
                onChange={(e) => setDescripcion(e.target.value)}

            />

            <input
                value="COTIZAR"
                type='submit'
                onClick={enviarFormulario}
            />

        </form>
      
    </div>
  )
}
