export const pelucheMagico = [
    {
        "img":"https://live.staticflickr.com/65535/53589717438_97216fe379_n.jpg",
        "nombre":"Caja sorpresa con peluche",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589717403_352e09628d_w.jpg",
        "nombre":"Caja kinder sorpresa",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589957005_4cc4cbb046_w.jpg",
        "nombre":"Caja circular peluche dulces",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589511816_cb681c306c_w.jpg",
        "nombre":"Caja de corazón peluche",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589717418_ce0ae6719b_w.jpg",
        "nombre":"Caja con ramo de dulces y peluche",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589717413_b5419155c4_w.jpg",
        "nombre":"Caja peluche sorpresa",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    {
        "img":"https://live.staticflickr.com/65535/53589830129_a2eaea36bb_n.jpg",
        "nombre":"Caja peluche sopresa y dulces",
        "descripcion":"sdfgsdf hjsgh whjtewtj ahrd ahjtethjr ahe ahte",
        "precio":"700.00"
    },
    

    
]